<template>
    <v-container>
        <v-row
        v-if="squareObject.ID">
            <v-col
            cols="12"
            sm="4">

                <square-main-menu
                :square="squareObject"
                @open-supplementary-editor="showSupplementaryEditor = true"/>


                <span>
                    {{squareObject.shortTitle}}
                </span>
            </v-col>

            <v-col
            cols="12"
            sm="5"
            >
                <span
                class="subtitle-1 font-weight-bold">
                {{squareObject.squareString}}
                </span>

                <span
                v-if="squareObject.floorString"
                class="subtitle-1">
                , {{squareObject.floorString}}
                </span>

            </v-col>


            <v-col
            cols="12"
            sm="3"
            >
                <div
                class="subtitle-1">
                {{squareObject.priceString}}
                </div>

                <div
                class="text-caption text--secondary">
                {{squareObject.meterPriceString}}
                </div>




            </v-col>
        </v-row>

        <square-supplementary-editor
        :square="squareObject"
        :show="showSupplementaryEditor"
        @on-change-data="refreshData"
        @close-dialog="showSupplementaryEditor = false">
        </square-supplementary-editor>

    </v-container>
</template>

<script>
import Square from '@/components/js/square.js'
import squareMainMenu from '@/components/entities/square/square-main-menu.vue'
import squareSupplementaryEditor from '@/components/entities/square/square-supplementary-editor.vue'

    export default {
        name: 'square-heading',
        components: {
            squareMainMenu,
            squareSupplementaryEditor,
        },
        props: {
            square: {
                type: Object,
            },
        },
        data () {
            return {
                squareObject: {},
                showSupplementaryEditor: false
            }
        },
        methods: {
            refreshData: function () {
                this.$emit('refresh-data');
            },
        },
        created() {
            this.squareObject = new Square(this.square);
        }
    }
</script>
