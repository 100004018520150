<template>
    <v-container>
        <v-row
        v-if="building.ID">
            <v-col
            cols="12"
            sm="9"
            class="d-flex align-center">

                <building-main-menu
                :buildingId="building.ID"/>
    
                <span>
                {{building.Address}}

                </span>
            </v-col>

            <v-col
            cols="12"
            sm="3"
            class="d-flex align-center subtitle-1 text--secondary">
                <v-chip
                small
                :color="buildingClass.color"
                class="mr-2">
                    {{buildingTypeAndClass}}
                </v-chip>
                <span
                v-if="building.Name">
                    {{building.Name}}
                </span>
            </v-col>
        </v-row>
        <v-row>
            <v-col
            cols="12"
            sm="9"
            class="subtitle-1 text--secondary">

                <span
                v-if="building.Metro">
                    метро {{building.Metro}}<span v-if="+building.FromMetro">, </span>
                </span>
                <span
                v-if="+building.FromMetro">
                    {{building.FromMetro}} мин.
                    <v-icon
                    small
                    v-if="building.isOnfoot">
                        mdi-walk
                    </v-icon>
                    <v-icon
                    small
                    v-if="!building.isOnfoot">
                        mdi-bus
                    </v-icon>
                </span>

                <a :href="'http://maps.yandex.ru/?text=' + building.Address" target="_blank">
                    <v-icon
                    right
                    color="red">
                        mdi-map-marker
                    </v-icon> карта
                </a>

                <a :href="cianLink" target="_blank"
                v-if="cianLink">
                    <v-icon
                    right
                    color="blue">
                        mdi-map-marker
                    </v-icon> cian
                </a>

            </v-col>

            <v-col
            cols="12"
            sm="3"
            class="subtitle-1 text--secondary">
                <span v-if="floors">{{floors}}</span>
                <span v-if="building.slLocation == 77">, первая линия</span>
                <span v-if="building.slLocation == 78">, на территории</span>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import declineNoun from '@/mixins/decline-nouns-after-num.js'
import buildingMainMenu from '@/components/entities/building/building-main-menu.vue'

    export default {
        name: 'building-heading',
        components: {
            buildingMainMenu,
        },
        props: {
            building: {
                type: Object,
            },
        },
        mixins: [declineNoun],
        data () {
            return {};
        },
        computed: {
            floors: function () {
                if (this.building.Floors) {
                    return this.building.Floors + this.declineNouns(this.building.Floors, ' этаж', ' этажа', ' этажей');
                } else {
                    return undefined;
                }
            },
            buildingClass: function () {
                if ( this.building.slBuildingClass == "17" ) {
                    return {
                        name: 'A',
                        color: 'yellow lighten-2'
                    };
                } else if ( this.building.slBuildingClass == "146" ) {
                    return {
                        name: 'B+',
                        color: 'lime lighten-2'
                    };
                } else if ( this.building.slBuildingClass == "18" ) {
                    return {
                        name: 'B',
                        color: 'green lighten-3'
                    };
                } else if ( this.building.slBuildingClass == "19" ) {
                    return {
                        name: 'C',
                        color: 'light-blue lighten-3'
                    };
                }
                else {
                    return {
                        name: undefined,
                        color: undefined
                    };
                }
            },
            buildingType: function () {
                if ( this.building.slBuildingType == "20" ) {
                    return 'БЦ';
                } else if ( this.building.slBuildingType == "21" ) {
                    return 'адм. здание';
                } else if ( this.building.slBuildingType == "22" ) {
                    return 'особняк';
                } else if ( this.building.slBuildingType == "23" ) {
                    return 'нежил. здание';
                } else if ( this.building.slBuildingType == "24" ) {
                    return 'жилой дом';
                } else if ( this.building.slBuildingType == "62" ) {
                    return 'торговый центр';
                } else if ( this.building.slBuildingType == "63" ) {
                    return 'пристройка к жил. дому';
                } else if ( this.building.slBuildingType == "64" ) {
                    return 'складской комплекс';
                } else {
                    return undefined;
                }
            },
            buildingTypeAndClass: function () {
                let result = this.buildingType;
                if (result && this.buildingClass.name) {
                    result += ' класса ' + this.buildingClass.name;
                }
                return result;
            },
            cianLink: function () {
                if (this.building.latitude && this.building.longitude) {
                    return 'https://www.cian.ru/map/?deal_type=rent&engine_version=2&offer_type=offices&office_type%5B0%5D=1&office_type%5B1%5D=2&office_type%5B2%5D=3&office_type%5B3%5D=4&office_type%5B4%5D=5&office_type%5B5%5D=7&office_type%5B6%5D=9&office_type%5B7%5D=10&office_type%5B8%5D=11&office_type%5B9%5D=12&region=1&zoom=19&center=' + (this.building.latitude / 100000) + ',' + (this.building.longitude / 100000)
                } else {
                    return '';
                }
            },
        },
    }
</script>
