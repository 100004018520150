<template>
    <v-container>

        <v-card
        v-if="(dataObject.request && dataObject.request.ID)"
        class="mb-2">

            <request-heading
            v-if="dataObject.request"
            :request="dataObject.request">
            </request-heading>

        </v-card>


        <v-card
        v-if="(dataObject.firmRenter && dataObject.firmRenter.ID)"
        class="mb-2">

            <firm-heading
            :firm="dataObject.firmRenter" class="word-break-normal"/>

        </v-card>


        <v-card
        v-if="(dataObject.square && dataObject.square.ID)"
        class="mb-2">

            <square-heading
            :square="dataObject.square"
            @refresh-data="refreshData">
            </square-heading>

            <square-supplementary
            v-if="dataObject.square"
            :square="dataObject.square">
            </square-supplementary>

        </v-card>

        <v-card
        v-if="(dataObject.building && dataObject.building.ID)"
        class="mb-2">

            <building-heading
            :building="dataObject.building">
            </building-heading>

            <building-supplementary
            v-if="dataObject.building"
            :building="dataObject.building">
            </building-supplementary>

        </v-card>

        <v-card
        v-if="(dataObject.fb && dataObject.fb.ID)"
        class="mb-2">

            <fb-heading
            :fb="dataObject.fb">
            </fb-heading>

        </v-card>

        <v-card
        v-if="(dataObject.firmLandlord && dataObject.firmLandlord.ID)"
        class="mb-2">

            <firm-heading
            :firm="dataObject.firmLandlord" class="word-break-normal"/>

        </v-card>



    </v-container>
</template>

<script>
import buildingHeading from '@/components/entities/building/building-heading.vue'
import buildingSupplementary from '@/components/entities/building/building-supplementary.vue'
import squareSupplementary from '@/components/entities/square/square-supplementary.vue'
import squareHeading from '@/components/entities/square/square-heading.vue'
import requestHeading from '@/components/entities/request/request-heading.vue'
import firmHeading from '@/components/entities/firm/firm-heading.vue'
import fbHeading from '@/components/entities/fb/fb-heading.vue'

export default {
    name: 'data-viewer',
    components: { 
      buildingHeading,
      buildingSupplementary,
      squareSupplementary,
      squareHeading,
      requestHeading,
      firmHeading,
      fbHeading

    },
    props: {
        dataObject: {
            type: Object,
        },
    },

    data () {
      return {
        showSquareSupplementaryEditor: false,
      }
    },

    methods: {
        refreshData: function () {
            this.$emit('refresh-data');
        },
    },


}
</script>