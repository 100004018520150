<template>
    <v-container>
        <v-row
        v-if="fb.ID">
            <v-col
            cols="12"
            sm="9">
                <fb-main-menu
                :fb="fb"/>
            </v-col>

            <v-col
            cols="12"
            lg="3">
                <staff-name-chip
                :id="fb.User_ID"
                class="mb-2"/>
            </v-col>

        </v-row>
        <v-row
        v-if="fb.About">
            <v-col>
                <div
                class="text--secondary word-break-normal">
                    {{fb.About}}
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <date-ins-change-del-tooltip time :item="fb"/>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>
import fbMainMenu from '@/components/entities/fb/fb-main-menu.vue'
import staffNameChip from '@/components/staff-name-chip.vue'
import dateInsChangeDelTooltip from '@/components/date-ins-change-del-tooltip.vue'

export default {
    name: 'fb-heading',
    components: {
        fbMainMenu,
        staffNameChip,
        dateInsChangeDelTooltip
    },
    props: {
        fb: {
            type: Object,
        },
    },
}
</script>
