export default {

    props: {
        params: {
            type: Object
        },
    },




    methods: {
        range: function (from,to,start,end) {
            let result = '';
            if (from) {
                result += " от " + new Intl.NumberFormat().format(from);
            }
            if (to) {
                result += " до " + new Intl.NumberFormat().format(to);
            }
            if (result){
                result = start + result + ' ' + end;
            }
            return result.trim();
        },
        dateRange: function (from, to) {
            let result = '';
            if (+from || +to) {
                if (+to === 0){
                    if ( +from === 1 ) {
                        result = "сегодня";
                    } else if ( +from === 7 ) {
                        result = "7 дней";
                    } else if ( +from === 30 ) {
                        result = "30 дней";
                    } else if ( +from === 90 ) {
                        result = "90 дней";
                    } else if ( +from === 180 ) {
                        result = "180 дней";
                    } else if ( +from === 365 ) {
                        result = "365 дней";
                    }
                }

                if ( !result ){
                    if ( +from ){
                        result = 'c ' + this.dateFrom(from) + ' по ' + this.dateTo(to);
                    }
                }
            }
            return result;
        },
        dateFrom: function (d) {
                let n = +d;
                if ( n > 0) {
                    n--;
                }
            return new Date(Date.now()-86400000*(n)).toISOString().slice(0, 10);
        },
        dateTo: function (d) {
            return new Date(Date.now()-86400000*(+d || 0)).toISOString().slice(0, 10);
        },
        setFields: function (obj) {
            if (obj) {
                this.$emit( 'setFields', obj )
            }
        },

    },

}