<template>
    <v-chip
    v-if="id"
    small
    class="mr-2"
    :color="color">
        <staff-name :id='id'/>
    </v-chip>
</template>

<script>
import staffName from '@/components/staff-name.vue'

export default {
    name: 'staff-name-chip',
    components: {
        staffName
    },
    props: {
        id: {
            type: Number,
            default: 0
        },
    },
    data () {
      return {}
    },
    computed: {
        color: function () {
            if (this.id > 0 && this.$store.state.staffFullNameList[this.id] && this.$store.state.staffFullNameList[this.id].disabled === 0){
                return 'primary'
            } else {
                return 'default'
            }
        },
    },


}
</script>